import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'rc-collapse/assets/index.css';
import { graphql, Link } from 'gatsby';

import Layout from '../components/GatsbyLayout';
import Helmet from '../components/Helmet';
import Banner from '../components/CaseStudyBanner';
import data from '../data/our-work-data';
import Sidebar from '../components/Navigation/Sidebar';

const categories = [
  {
    id: 'web-development',
    image: require('../assets/images/svg/services-web.svg'),
    title: 'Web Development',
    description: 'Our team of top notch architects & full-stack engineers works with the latest technologies and '
      + 'tools to help you accomplish your business goals. We build modern, scalable web applications that are custom'
      + ' tailored to your needs and growth goals.',
    tags: ['Front-end Engeneering', 'Back-end Engeneering', 'Systems Arhitecture', 'Websites', 'Web Apps & Platforms',
      'Ecommerce'],
    path: 'web-development/'
  },
  {
    id: 'app-development',
    image: require('../assets/images/svg/services-mobile.svg'),
    title: 'App Development',
    description: 'We build state-of-the-art iOS and Android apps for a variety of industries and business domains. '
      + 'Be it native or cross-platform apps, we use the latest technologies to build innovative mobile solutions that'
      + ' provide real business value.',
    tags: ['iOS Development', 'Android Development', 'Cross Platform', 'Xamarin', 'React Native', 'Flutter'],
    path: 'app-development/'
  },
  {
    id: 'ideation-and-product-strategy',
    image: require('../assets/images/svg/services-ideation.svg'),
    title: 'Ideation & Product Strategy',
    description: 'Product strategy is at the heart of every successful digital product. Our dedicated team can help'
      + ' you understand your users, align their needs with the goals of your business and map out a strategy'
      + ' for success.',
    tags: ['Discovery Workshops', 'Design Sprints', 'Innovation Consulting', 'UX Audit', 'Competitive Analysis',
      'Product Roadmap'],
    path: 'ideation-and-product-strategy/'
  },
  {
    id: 'ux-ui-design',
    image: require('../assets/images/svg/services-ux.svg'),
    title: 'UX/UI Design',
    description: 'Our team of UX experts can help you build digital products that delight your users as well as add '
      + 'value to your business. We make it our business to deliver massive ROI by designing intuitive user experiences'
      + ' that drive results.',
    tags: ['Discovery & Strategy', 'User Experience Design', 'User Interface Design', 'Design Systems', 'Prototyping',
      'Interaction Design'],
    path: 'ux-ui-design/'
  },
  {
    id: 'cloud-infrastructure',
    image: require('../assets/images/svg/services-cloud.svg'),
    title: 'Cloud Infrastructure',
    description: 'Our dedicated team of devops and infrastructure architects can help you optimize and scale your '
      + 'business at any stage of your cloud journey. From cloud assessment and strategy to infrastructure setup,'
      + ' migration and maintenance, we’ve got your back.',
    tags: ['Continuous Integration', 'Infrastructure Management', 'Cloud Migration Services', 'Cloud Integration',
      'Hosting', 'Big Data'],
    path: 'cloud-infrastructure/'
  }
];

export default class Services extends Component {
  componentDidMount() {
    this.simulateMouseClickOnTarget();
  }

  simulateMouseClickOnTarget = () => {
    if (window) {
      const hashElements = window.location.hash.split('#');

      if (!Array.isArray(hashElements) || hashElements.length < 1) {
        return;
      }

      const hashTarget = hashElements[1] ? hashElements[1].replaceAll('%20', ' ') : '';
      const matchTarget = document.getElementById(hashTarget);

      if (matchTarget && typeof matchTarget.click === 'function') {
        setTimeout(() => {
          matchTarget.click();
        }, 400);
      }
    }
  }

  renderHelmet = () => (
    <Helmet
      title="High Quality Engineering and Managing Services | MCRO"
      description="MCRO offers top services for companies all
      around the world, from Product Design to Full Stack Engineering and Maintenance."
      keywords="Top Consultants, Web Development, Javascript, React, React Native, ExtJS,
      Sencha, Angular, Ionic, iOS, Android, NodeJS, PHP, AWS, DevOps, Security, QA, Automation, Blockchain"
      canonical
      canonicalPage="services"
    />
  );

  renderCategoryProjects = (categoryName) => {
    const projects = data.filter(item => item.servicesCategory.includes(categoryName));

    return (
      <div key={categoryName} className="scroll-page__category-projects">
        {
          projects.map(project => (
            <Link key={project.id} className="project" to={`/our-work/${project.linkId}/`}>
              <div className="thumbnail">
                <img src={project.cardImage} alt={project.title} title={project.title} />
              </div>
              <div className="content">
                <img
                  className="logo"
                  src={project.logo}
                  alt={`${project.title} Logo`}
                  title={`${project.title} Logo`}
                />
                <h3 className="font-body1 content__description" tabIndex="0">
                  {project.cardDescription}
                </h3>
              </div>
            </Link>
          ))
        }
      </div>
    );
  };

  renderCategory = (category) => {
    const { title, description, id, path, image, tags } = category;

    return (
      <div key={title.toUpperCase()} id={title.toUpperCase()} className="scroll-page__category">
        <Link key={id} to={`/services/${path}`}>
          <div className="scroll-page__category-details">
            <div className="content-wrapper">
              <div className="details">
                <h2 className="font-large font-weight-bold" tabIndex="0">
                  {title}
                </h2>
                <div className="description font-body1" tabIndex="0">
                  {description}
                </div>
              </div>
              <div className="thumbnail">
                <img src={image} alt={title} title={title} />
              </div>
            </div>
            <ul className="tags">
              {tags.map(tag => <li key={`category-tag-${tag}`} className="tag" tabIndex="0">{tag}</li>)}
            </ul>
          </div>
        </Link>
        {this.renderCategoryProjects(title)}
      </div>
    );
  };

  render() {
    const { location, data: gatsbyData } = this.props;
    const fluidSrc = gatsbyData.file.childImageSharp.fluid;
    return (
      <Layout location={location}>
        {this.renderHelmet()}
        <div className="main services main__inner">
          <Banner
            category="SERVICES"
            title="Unique solutions for your business"
            description="Our mission is to bring your ideas to life through creative thinking and up-to-date
            technology solutions."
            onButtonPress="/contact/"
            buttonText="Get a quote"
            fluid={fluidSrc}
          />
          <div className="main-container">
            <Sidebar className="sidebar-menu" all={categories.map(cat => cat.title)} />
            <div className="scroll-page">
              {categories.map(category => this.renderCategory(category))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

Services.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query servicesPage {
    file(relativePath: { eq: "services-header-image.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 550)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
