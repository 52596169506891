import { Link, Element } from 'react-scroll';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

const Sidebar = ({ all }) => {
  let scrollOffset = -75;
  const isTablet = useMediaQuery({
    query: '(max-device-width: 980px)'
  });

  let scrollTime = 500;
  const isPhone = useMediaQuery({
    query: '(max-device-width: 480px)'
  });

  if (isPhone) {
    scrollTime = 350;
    scrollOffset = -130;
  }

  if (isTablet) {
    scrollOffset = -120;
  }

  const itemsToShow = all.map((name, index) => (
    <div key={name} className={`sidebar__item sidebar__item-${index}`}>
      <Link
        activeClass="sidebar__item_active "
        to={name.toUpperCase()}
        spy
        smooth
        offset={scrollOffset}
        duration={scrollTime}
        tabIndex="0"
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            document.querySelector(`.sidebar__item-${index} a`).click();
          }
        }}
      >
        <Element id={name} className="sidebar__name">
          <i className="fa fa-caret-right" />
          <span>{name}</span>
        </Element>
      </Link>
    </div>
  ));

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar">
        {itemsToShow}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  all: PropTypes.arrayOf(PropTypes.string),
};

Sidebar.defaultProps = {
  all: null,
};

export default Sidebar;
