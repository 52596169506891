import React from 'react';
import Img from 'gatsby-image';

import Subtitle from './Subsection/Subtitle';
import Button from './Button';

const CaseStudyBanner = (props) => {
  const { category, title, description, onButtonPress, buttonText,
    customButton, imgSource, fluid, icon, className } = props;
  const type = icon ? 'primary-large-light-icon-2' : 'primary-large-light';
  const visibleTitle = title.split('|')[0].trim();

  return (
    <div className={`case-study-banner ${className}`}>
      <div className="case-study-banner--section">
        <div className="case-study-banner--section__category" tabIndex="0">{category}</div>
        <Subtitle
          titleType="withDotH1"
          withoutLine
          title={visibleTitle}
        />
        <p className="case-study-banner--section__text" tabIndex="0">{description}</p>
        {customButton || (
          <Button
            goTo={onButtonPress}
            internalLink
            type={type}
            title={buttonText}
            classNames="case-study-banner--section__button"
            icon={icon}
          />
        )}
      </div>
      {fluid ? (
        <Img
          fluid={fluid}
          className="case-study-banner--image about-us__banner-image"
          alt={title}
          title={title}
        />
      ) : (
        <img className="case-study-banner--image about-us__banner-image" src={imgSource} alt={title} title={title} />
      )}
    </div>
  );
};

export default CaseStudyBanner;
